import React from 'react';
import {Container, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import line2019 from "assets/img/about/about-line-2019.png";
import lineNow from "assets/img/about/about-line-now.png";

import scss from './about.module.scss';

const About = ({screens = {}}) => {
    const {isMdScreen} = screens;

    return (
        <ParallaxProvider>
            <section className={scss.aboutSection} id="about-section">
                <Container>
                    <Col lg={{span: 5, offset: 6}}>
                        <div className={`${scss.item} ${scss.itemBefore}`}>
                            <div className={scss.text}>
                                <h2><span>2019</span><br/> Начало&#8239;пути
                                    <span className={scss.lineAround}><img src={line2019} alt="Line"/></span></h2>
                                <p>Будучи маркетологом, я пошла на курсы дизайна с нуля. Это захлестнуло меня с
                                    головой, я уволилась и начала карьеру дизайнера.</p>
                            </div>
                            <Parallax
                                translateX={isMdScreen ? ['-100px', '50px'] : ['200px', '0px']}
                                opacity={[0, 1]}
                                className={`${scss.bg} parallax`}
                            >
                                <h4>2019</h4>
                            </Parallax>
                        </div>
                    </Col>
                    <Col lg={{span: 5, offset: 1}}>
                        <div className={`${scss.item} ${scss.itemNow}`}>
                            <div className={scss.text}>
                                <h2><span>2025</span><br/> Прямо&#8239;сейчас
                                    <span className={scss.lineBottom}><img src={lineNow} alt="Line"/></span></h2>
                                <p>За 5+ лет в дизайне создала десятки проектов и работала с такими брендами, как CREAMLY, Vans,
                                    Volkswagen, Glo, Ziaja, Hunting Pony. И это только начало!</p>
                            </div>
                            <Parallax
                                translateX={isMdScreen ? ['-50px', '100px'] : ['-200px', '0px']}
                                opacity={[0, 1]}
                                className={`${scss.bg} parallax`}
                            >
                                <h4>2025</h4>
                            </Parallax>
                        </div>
                    </Col>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default About;
